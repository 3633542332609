<template>
  <div class="similar-rvs">
    <LazyZBreadcrumb
      v-if="location"
      :items="breadcrumbList"
      target="_blank"
      rel="noopener noreferrer"
    />

    <GridList
      :results-length="rvs?.length"
      :page-size="quantity"
      :rows="1"
    >
      <template #default="{ visibleResults, queueViewableImpression }">
        <CardRv
          v-for="(card, index) in visibleResults"
          :key="card.pregen_id"
          :index="index"
          search-request-id="none"
          :is-loading="pending"
          :rv="rvs?.[index]"
          cta="similar"
          @visible:rv="queueViewableImpression"
        />
      </template>
    </GridList>
  </div>
</template>

<script setup lang="ts">
import type { RvSimilarSearchResults, RvUnifiedSearch, Breadcrumb } from '~/types'

import { COUNTRY_SHORTS, ROUTE_BASE_NAME } from '~/constants'

const props = withDefaults(defineProps<{
  rvId: string
  location?: {
    City: string
    Country: string
    State: string
  }
  quantity?: number
}>(), {
  quantity: 3,
})

const emit = defineEmits(['loaded'])

const { t } = useI18n()

const rvs: Ref<RvUnifiedSearch[]> = ref([])
const { $captureError } = useNuxtApp()

const runtimeConfig = useRuntimeConfig()
const route = useRoute()
const { StartDate: start, EndDate: end } = route.query

const { data, pending, error } = await useLazyFetch<RvSimilarSearchResults>(`${runtimeConfig.public.apiUrl}/api/rvlistings/similar-rvs`, {
  params: {
    RVId: props.rvId,
    StartDate: start ? formatAsDateISO(start as string) : null,
    EndDate: end ? formatAsDateISO(end as string) : null,
    RVCount: props.quantity,
  },
})

if (data.value?.ListRVs) {
  rvs.value = data.value.ListRVs
  emit('loaded')
}

if (error.value) {
  $captureError(error.value)
}

const localePath = useLocalePath()
const breadcrumbList = computed(() => {
  // TODO: switch this to using point of interest, sometimes the city won't be
  // found in its current state.
  if (props.location) {
    const { Country, State, City } = props.location
    const base: Breadcrumb[] = [
      {
        text: t('pageLink.home'),
        to: localePath({
          name: 'index',
        }),
        active: true,
      },
    ]

    if (Country) {
      base.push({
        text: normalizeCountry(Country),
        to: localePath({
          name: ROUTE_BASE_NAME.rv_rentals_destination,
          params: {
            destinationPath: [formatAsSlug(normalizeCountryPath(Country))],
          },
        }),
        active: true,
      })
    }

    if (State) {
      base.push({
        text: formatSlugAsString(State),
        to: localePath({
          name: ROUTE_BASE_NAME.rv_rentals_destination,
          params: {
            destinationPath: [formatAsSlug(normalizeCountryPath(Country)), formatAsSlug(State)],
          },
        }),
        active: true,
      })
    }

    if (City) {
      base.push({
        text: formatSlugAsString(City),
        to: localePath({
          name: ROUTE_BASE_NAME.rv_rentals_destination,
          params: {
            destinationPath: [
              formatAsSlug(normalizeCountryPath(Country)),
              formatAsSlug(State),
              formatAsSlug(City),

            ],
          },
        }),
        active: false,
      })
    }

    return base
  }
  return []
})

function normalizeCountry(countryShort: string) {
  switch (countryShort) {
    case COUNTRY_SHORTS.US:
      return 'USA'
    case COUNTRY_SHORTS.CA:
      return 'Canada'
    default:
      return formatSlugAsString(countryShort)
  }
}

function normalizeCountryPath(countryShort: string) {
  switch (countryShort) {
    case COUNTRY_SHORTS.US:
      return 'united-states'
    case COUNTRY_SHORTS.CA:
      return 'canada'
    default:
      return formatAsSlug(countryShort)
  }
}
</script>

<style lang="scss" scoped>
.cards-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.875rem;

  @include media-min-size(large) {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
